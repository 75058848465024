import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import { LoadingSpinner } from "components/Interface/LoadingSpinner.tsx";
import { Label } from "components/UI/Label";
import { NavArrowRight } from "iconoir-react";
import * as React from "react";
import { Link, type LinkProps } from "react-router";
import { cn } from "utils/utils.ts";
// absolute right-0
const iconVariants = cva(
  "rounded-sm aspect-square p-2 bg-primary data-[state=disabled]:bg-green-300 text-white h-auto",
  {
    variants: {
      visibility: {
        visible: "block",
        hidden: "hidden",
      },
      size: {
        large: "w-[52px]",
        medium: "w-12",
        small: "w-11",
        xs: "w-10",
      },
    },
  },
);

const buttonVariants = cva(
  "inline-flex relative items-center justify-center whitespace-nowrap rounded-sm border-0 border-transparent text-sm font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-75 transition-colors duration-150 h-fit cursor-pointer",
  {
    variants: {
      variant: {
        primary:
          "bg-secondary hover:bg-secondaryDarker active:bg-primary text-white disabled:bg-gray-400 disabled:text-gray-50",
        secondary:
          "bg-white border-0 disabled:text-gray-400 disabled:bg-gray-200 text-secondary hover:bg-green-100 active:bg-primary active:text-white",
        secondaryGray100:
          "bg-gray-100 disabled:text-gray-400 disabled:bg-gray-200 text-secondary hover:bg-green-100 active:bg-primary active:text-white",
        tertiary:
          "bg-transparent border-2 border-gray-300 disabled:text-gray-500 disabled:border-gray-200 hover:border-green-300 active:text-primary active:border-green-500 text-secondary",
        midnightblue:
          "bg-white text-secondary hover:bg-green-100 active:bg-primary active:text-white",
        teal: "bg-secondary hover:bg-secondaryDarker active:bg-primary text-white",
        green: "bg-primary hover:bg-green-600 active:bg-green-700 text-white",
        outline: "bg-white border-2 border-gray-300 text-secondary",
        ghost: "bg-white border-2 border-gray-300 text-secondary",
        progress: "bg-white border-2 border-gray-300 text-secondary",
        marketplaceLight: "bg-white border-2 border-gray-300 text-secondary",
        destructive: "bg-rouge border-2 border-gray-300 text-white",
        "dark-iron": "bg-white border-2 border-gray-300 text-secondary",
        link: "bg-white border-2 border-gray-300 text-secondary",
        datepicker:
          "flex w-full rounded-[8px] font-medium border border-gray-200 bg-white px-4 py-3 text-sm text-gray-800 active:text-secondary ring-0 ring-offset-0 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus:outline-0 focus-visible:border-teal focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 enabled:active:border-2 active:border-green-500 enabled:hover:border-green-400 transition-all duration-150 enabled:active:outline-0 enabled:active:ring-0 disabled:cursor-not-allowed disabled:text-gray-300 disabled:bg-gray-100",
      },
      size: {
        large: "p-3 pb-4 text-lg",
        lg: "p-3 pb-4 text-lg",
        medium: "p-2.5 pb-3 text-base",
        small: "p-3 pb-4 text-sm",
        sm: "p-3 pb-4 text-sm",
        xs: "p-2.5 pb-3 text-xs",
        icon: "p-2.5 pb-3 text-xs",
        default: "p-2.5 pb-3 text-xs",
        marketplace: "p-2.5 pb-3 text-xs",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "small",
    },
  },
);

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export const LoadingButton = ({
  isLoading,
  disabled = false,
  children,
  type,
  name,
  value,
  variant = "primary",
  size = "small",
  className = "",
  formId = undefined,
  onClick,
}: {
  className?: string;
  isLoading: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  name?: string;
  value?: string;
  type: "button" | "submit" | "reset";
  formId?: React.ButtonHTMLAttributes<HTMLButtonElement>["form"];
  onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>["onClick"];
} & Required<VariantProps<typeof buttonVariants>>) => {
  return (
    <ClintButton
      as="Button"
      type={type}
      name={name}
      form={formId}
      value={value}
      onClick={onClick}
      disabled={disabled || isLoading}
      variant={variant}
      size={size}
      className={className}
    >
      {children}
    </ClintButton>
  );
};

type CommonPropsType = {
  className?: string;
  labelClassName?: string;
  children: React.ReactNode;
  labelSize?: "M" | "S" | "XS" | "L";
  textAlign?: "text-center" | "text-left" | "text-right";
  hideIcon?: boolean;
  hideLabel?: boolean;
  iconPosition?: "left" | "right";
  Icon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> &
      React.RefAttributes<SVGSVGElement>
  >;
} & VariantProps<typeof buttonVariants> &
  Required<Pick<VariantProps<typeof buttonVariants>, "variant">>;

type ButtonPropsType = {
  as: "Button";
  isLoading?: boolean;
  disabled?: boolean;

  name?: string;

  value?: string;
  type?: "button" | "submit" | "reset";
  form?: React.ButtonHTMLAttributes<HTMLButtonElement>["form"];
  onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>["onClick"];
} & CommonPropsType;

type LinkPropsType = {
  as: "Link";
  linkProps: LinkProps & {
    obfuscate?: boolean;
  };
} & CommonPropsType;

type AnchorPropsType = {
  as: "a";
  anchorProps: React.AnchorHTMLAttributes<HTMLAnchorElement>;
} & CommonPropsType;

type DivPropsType = {
  as: "div";
} & CommonPropsType;

export type ClintButtonType =
  | LinkPropsType
  | ButtonPropsType
  | AnchorPropsType
  | DivPropsType;

export const ClintButton = (buttonProps: ClintButtonType) => {
  const [isObfuscated, setIsObfuscated] = React.useState(
    buttonProps.as === "Link" && buttonProps.linkProps.obfuscate,
  );
  React.useEffect(() => {
    if (!isObfuscated) return;
    setIsObfuscated(false);
  }, [isObfuscated]);

  const {
    as,
    hideIcon,
    children,
    // @ts-ignore
    textAlign,
    className,
    hideLabel,
    iconPosition,
    size,
    // @ts-ignore
    labelSize,
    variant,
    labelClassName,
  } = buttonProps;
  const iconVariant: VariantProps<typeof iconVariants> = {
    // @ts-ignore
    size: size,
    visibility: variant === "tertiary" || hideIcon ? "hidden" : "visible",
  };
  const paddingAttributes = buttonVariants({ size, variant })
    .split(" ")
    .filter((attr) => attr.startsWith("p-") || attr.startsWith("pb-"));
  const hasIcon = iconVariant.visibility === "visible";
  const Comp =
    as === "Button"
      ? Button
      : as === "Link" && isObfuscated
        ? "span"
        : as === "Link"
          ? Link
          : "a";
  const isButton = as === "Button";
  const disabled = isButton
    ? buttonProps.disabled || buttonProps.isLoading
    : false;

  let { Icon } = buttonProps;

  if (!Icon) {
    Icon = NavArrowRight;
  }
  return (
    // @ts-expect-error lol there is no error here
    <Comp
      {...(isButton
        ? {
            type: buttonProps.type,
            name: buttonProps.name,
            form: buttonProps.form,
            value: buttonProps.value,
            disabled,
            onClick: buttonProps.onClick,
            variant: buttonProps.variant,
            size: buttonProps.size,
          }
        : as === "Link"
          ? {
              ...buttonProps.linkProps,
              obfuscate: undefined,
            }
          : as === "a"
            ? {
                ...buttonProps.anchorProps,
                obfuscate: undefined,
              }
            : {})}
      disabled={disabled}
      className={cn(
        buttonVariants({
          size,
          variant,
        }),
        "min-w-fit cursor-pointer justify-between overflow-hidden",
        {
          "p-0": hasIcon,
          "flex-row-reverse": iconPosition === "left",
        },
        className,
      )}
    >
      {!hideLabel ? (
        <Label
          size={labelSize ?? "S"}
          variant={"primary"} // The parent controls the color so we set text to inherit
          className={cn(
            `text-inherit shrink-0 grow ${textAlign ? textAlign : "text - center"} cursor-pointer`,
            hasIcon ? [paddingAttributes, "py-0"] : [],
            labelClassName,
          )}
        >
          {children}
        </Label>
      ) : null}
      {iconVariant.visibility === "hidden" ? null : Icon ? (
        <div
          className={cn(
            "bg-primary flex aspect-square size-12 h-auto items-center justify-center rounded-sm text-white data-[state=disabled]:bg-green-300",
            {
              "ml-auto": iconPosition === "right",
              "bg-red-900": variant === "destructive",
            },
          )}
        >
          {isButton && buttonProps.isLoading ? (
            <LoadingSpinner
              loading
              data-state={disabled ? "disabled" : "enabled"}
              className={cn("size-5")}
            />
          ) : (
            <Icon
              strokeWidth={2}
              data-state={disabled ? "disabled" : "enabled"}
              className={"size-5 shrink-0"}
              aria-hidden="true"
            />
          )}
        </div>
      ) : null}
    </Comp>
  );
};

export { Button, buttonVariants, iconVariants };
